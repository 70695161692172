const ENVIRONMENT = {
  local: {
    REACT_APP_BACKEND_URL: 'https://aquatic.rovula.com/api',
    REACT_APP_ACCESS_TOKEN:
      'GE0MHA5ZDRncjJhdGhtZGZ2Iiwib3JpZ2luX2p0aSI6IjY2NDI1MWEzLTkxYWYtNGEwOS05MjdiLTc0NzRjMDEyZWI3ZiIsImV2ZW50X2lkIjoiZDIyYWVjMmQtOGJlMC00MTk5LWIzMjMtODEyZjNkYTZlMzM3IiwidG9rZW5fdXNlIjoiYWNjZXNzIiwic2NvcGUiOiJhd3MuY29nbml0by5zaWduaW4udXNlci5hZG1pbiIsImF1dGhfdGltZSI6MTY2MTgzMDMwMiwiZXhwIjoxNjYxODMzOTAyLCJpYXQiOjE2NjE4MzAzMDIsImp0aSI6IjhjZDYxZDczLTkyNmMtNDVlZC05NTM4LTVlOWE4OTEyMjAzMyIsInVzZXJuYW1lIjoianVsaWVuZEByb3Z1bGEuY29tIn0.aQ1dra7F4Z3NRW-I5UuK0fvwXPD4omWo9jAyvdzw_zBzkGPM0hqk6M9iq4DQzc08Zc_56WJf9Yr-EMwCOXjVSPty6Qp5AspNHYNDSF1aTDbJaTjmHSMS0EZXWiL_4XHzXzUArANClLnyntsUgyfiE2FZ_s-qFoi71RnpUPzVxBQuj_SKGGDesiF9IN7qUGAvfcJfIpAmrc2Wd_bl7eZ5qOxHHN9G--FkNPQBSwGgafzvSO7d3pHTVaPJPzt2oU8GtHJQVT_LEGBkbhQB5H5S9qiW3sdtSuRDHXsSOL3XsyCqBLMIqXe0odhP6MSO4xgJbAUgoVzLeB5Ijk64SBDHHQ'
  },
  development: {
    REACT_APP_BACKEND_URL: 'https://aquatic.rovula.com/api',
    REACT_APP_ACCESS_TOKEN:
      'eyJraWQiOiJCUXNKa0RKQ1c4UTh6dmhFa084enFUTTVNZ2NyVmlCZmoxRWRvWkt1S0VrPSIsImFsZyI6IlJTMjU2In0.eyJzdWIiOiI1ZDFjMTQ2ZS1jNjcyLTQxZmUtYmYxNi0xOGFjNGIzNTkzMDciLCJjb2duaXRvOmdyb3VwcyI6WyJNYXN0ZXIiXSwiaXNzIjoiaHR0cHM6XC9cL2NvZ25pdG8taWRwLmFwLXNvdXRoZWFzdC0xLmFtYXpvbmF3cy5jb21cL2FwLXNvdXRoZWFzdC0xX2p4dk5NeXZtViIsImNsaWVudF9pZCI6IjdxbGRpaDY4NGE0MHA5ZDRncjJhdGhtZGZ2Iiwib3JpZ2luX2p0aSI6ImE5NmViZDJjLTM4OTgtNDU2Mi04NjE2LWQwZTBhZTYxMmUyMyIsImV2ZW50X2lkIjoiYjBlMTVkMmEtYmE2MS00ZWQ5LWJkZWQtMmUxODBhODhmMDUwIiwidG9rZW5fdXNlIjoiYWNjZXNzIiwic2NvcGUiOiJhd3MuY29nbml0by5zaWduaW4udXNlci5hZG1pbiIsImF1dGhfdGltZSI6MTY1NzYxNzU3OSwiZXhwIjoxNjU3NjIxMTc5LCJpYXQiOjE2NTc2MTc1NzksImp0aSI6ImQ2YzEyNWEyLTNjYTItNGMyYy1hYjQ1LTRmZDY3NjEwNDkwNCIsInVzZXJuYW1lIjoibnV0dGFwb25nc2FAcm92dWxhLmNvbSJ9.S3O8MC_ZdcgzpTp0sSgYfF6tQLXZLh82TeEbvDQqOC74xuCx_8ww2j6RPmNWZ0a8MQ0Ku18yAeUvc3nYAJmZqT6lKh0mFxHqKDkcZdJHj_0iYKRe53-dvTTqredjZnuUVwaV3Dt6l29KrCh_D73Mm-eb2fuEcYKyuj5S63dMvlqzyUsegm06xpWcyT_1RwjZh8lWC-e17gz5Go4bPMQcGRBRHiIBe57p95z3qzxGS9G27nDWmGMhP6rG-x2MjQKe1UFSTVxGOqjRU0hKm3QQkjZ9Y3UZmcn_awHsDV11b31ope3fZHtcat72SfTq3HEdr8QK-PB_MW9ad2bdwh1Y_A'
  },
  qa: {
    REACT_APP_BACKEND_URL: 'https://aquatic.rovula.com/api',
    REACT_APP_ACCESS_TOKEN:
      'eyJraWQiOiIxblgzSlwva0IyXC84Y0dvUUFcLyszb2pVOEkyaWwwTjZhVUFGWVhzY0w4YlhBPSIsImFsZyI6IlJTMjU2In0.eyJzdWIiOiI3Y2ZhNWYxZi04NjljLTQ3MjgtOTJjZC0yMGRiNmU5ZWZmNTQiLCJjb2duaXRvOmdyb3VwcyI6WyJNYXN0ZXIiXSwiaXNzIjoiaHR0cHM6XC9cL2NvZ25pdG8taWRwLmFwLXNvdXRoZWFzdC0xLmFtYXpvbmF3cy5jb21cL2FwLXNvdXRoZWFzdC0xX3hHdndQU01lWCIsImNsaWVudF9pZCI6IjVmdHY5c2d2ZWdoMG9tbTZ2Z2oybTFhNmNmIiwib3JpZ2luX2p0aSI6IjM1ODM4MjRhLThmZjYtNGQ1Mi1hZmRlLTMzNWEwMzlmNzQ4MyIsImV2ZW50X2lkIjoiNTkyMDUwYWYtYzdjNi00M2VjLWE3ODctNTFjYjY4NTU5YjcwIiwidG9rZW5fdXNlIjoiYWNjZXNzIiwic2NvcGUiOiJhd3MuY29nbml0by5zaWduaW4udXNlci5hZG1pbiIsImF1dGhfdGltZSI6MTY2MTgzMjM0OSwiZXhwIjoxNjYxODM1OTQ5LCJpYXQiOjE2NjE4MzIzNDksImp0aSI6IjlkY2MxMDg1LTBmZWQtNDI0NS1hMjQ5LTliYTk2MzkwMjQxZiIsInVzZXJuYW1lIjoibnV0dGF3YXRuQHJvdnVsYS5jb20ifQ.kDViuL3K4_QctVPaB3B9o3RygNYVY6iwvqXtKy_XcB9iF1kh36yxt7mu8xLvj0PrBke2J65M2bias9QtQQpXXP4nCdjMqefFt6QXzctQjE_Igm0cuwnoVGXm85fj_ZJaS1LdocLPvz3mJCADlT9xJ9TzFZPWD_0jhDXmzpwLa9Gh3cbMEfAH0pvpixSknay_DW7Zs6LuBq9Yw64ko_pm_tHQG8vJprnYpek5WD7L0P37ZzZfAQ9DfC4-f7CNnnC8g550Wb8no0PnKWiUlYcAvVj7KSKjMGuarxRk-Gqwm2vJhrSA_3odEQXzplKWIwOoUIjcbEfW7b-UrSz8WoQZJQ'
  },
  production: {
    REACT_APP_BACKEND_URL: 'https://aquatic.rovula.com/api',
    REACT_APP_ACCESS_TOKEN:
      'GE0MHA5ZDRncjJhdGhtZGZ2Iiwib3JpZ2luX2p0aSI6IjY2NDI1MWEzLTkxYWYtNGEwOS05MjdiLTc0NzRjMDEyZWI3ZiIsImV2ZW50X2lkIjoiZDIyYWVjMmQtOGJlMC00MTk5LWIzMjMtODEyZjNkYTZlMzM3IiwidG9rZW5fdXNlIjoiYWNjZXNzIiwic2NvcGUiOiJhd3MuY29nbml0by5zaWduaW4udXNlci5hZG1pbiIsImF1dGhfdGltZSI6MTY2MTgzMDMwMiwiZXhwIjoxNjYxODMzOTAyLCJpYXQiOjE2NjE4MzAzMDIsImp0aSI6IjhjZDYxZDczLTkyNmMtNDVlZC05NTM4LTVlOWE4OTEyMjAzMyIsInVzZXJuYW1lIjoianVsaWVuZEByb3Z1bGEuY29tIn0.aQ1dra7F4Z3NRW-I5UuK0fvwXPD4omWo9jAyvdzw_zBzkGPM0hqk6M9iq4DQzc08Zc_56WJf9Yr-EMwCOXjVSPty6Qp5AspNHYNDSF1aTDbJaTjmHSMS0EZXWiL_4XHzXzUArANClLnyntsUgyfiE2FZ_s-qFoi71RnpUPzVxBQuj_SKGGDesiF9IN7qUGAvfcJfIpAmrc2Wd_bl7eZ5qOxHHN9G--FkNPQBSwGgafzvSO7d3pHTVaPJPzt2oU8GtHJQVT_LEGBkbhQB5H5S9qiW3sdtSuRDHXsSOL3XsyCqBLMIqXe0odhP6MSO4xgJbAUgoVzLeB5Ijk64SBDHHQ'
  }
};

const getValue = (key) => {
  const NODE_ENV = process.env.REACT_APP_NODE_ENVIROMENT || 'local';
  const value = ENVIRONMENT[NODE_ENV][key];
  if (!value) {
    throw new Error(`config error - missing env.${key}`);
  }

  return value;
};
const config = { getValue };
export default config;
