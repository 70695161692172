import { CardHeader, styled, Button, Typography, Grid } from '@mui/material';
import PropTypes from 'prop-types';
import { PieChart, Pie, Legend, Tooltip, ResponsiveContainer, Cell, Sector } from 'recharts';
import { useEffect, useMemo, useRef, useState } from 'react';
import PieTypeChartTooltip from 'layouts/dashboard/PieTypeChartTooltip';
import PieTypeChartLegend from 'layouts/dashboard/PieTypeChartLegend';
import breakpoints from 'theme/breakpoints';
import useInnerWidth from 'hooks/useInnerWidth';
import { debounce, orderBy } from 'lodash';
import { useTranslation } from 'react-i18next';
import CustomChartLegend from 'layouts/dashboard/CustomChartLegend';
import { fDecimal, fNumber } from 'utils/formatNumber';

// ----------------------------------------------------------------------

const CHART_HEIGHT = '360px';

const ChartWrapperStyle = styled('div')`
  height: 100%;
  margin-top: 24px;
  padding-bottom: 32px;

  @media only screen and (max-width: 599px) and (min-width: 0px) {
    & .chart-data {
      position: relative;
      width: 100%;
      height: 320px;
    }
    & .legend-data {
      display: flex;
      justify-content: center;
      width: 100%;
      height: 100%;
      padding-left: 24px;
    }
  }
  @media only screen and (max-width: 899px) and (min-width: 600px) {
    & .chart-data {
      position: relative;
      width: 100%;
      height: 320px;
    }
    & .legend-data {
      display: flex;
      justify-content: center;
      width: 100%;
      height: 100%;
      padding-left: 24px;
    }
  }
  @media only screen and (max-width: 1199px) and (min-width: 900px) {
    & .chart-data {
      position: relative;
      width: 100%;
      height: 320px;
    }
    & .legend-data {
      display: flex;
      justify-content: center;
      width: 100%;
      height: 100%;
    }
  }
  @media only screen and (max-width: 1535px) and (min-width: 1200px) {
    & .chart-data {
      position: relative;
      width: 100%;
      height: 320px;
    }
    & .legend-data {
      display: flex;
      justify-content: center;
      width: 100%;
      height: 100%;
    }
  }
  @media only screen and (min-width: 1536px) {
    & .chart-data {
      position: relative;
      width: 100%;
      height: 320px;
    }
    & .legend-data {
      display: flex;
      justify-content: center;
      width: 100%;
      height: 100%;
    }
  }
`;
const ChartResponsive = styled(ResponsiveContainer)`
  width: 100%;
  height: 100%;
`;
const TotalInChart = styled('div')`
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: -1;
  display: flex;
  flex-direction: column;
  align-items: center;
  transform: translate(-50%, -50%);
  color: #000000;
  & .label {
    font-size: 16px;
    line-height: 14px;
    font-weight: 400;
    width: 120px;
    text-align: center;
  }
  & .value {
    font-size: 48px;
    line-height: 48px;
    font-weight: 700;
  }
  @media only screen and (max-width: 599px) and (min-width: 0px) {
    & .label {
      font-size: 13px;
      line-height: 14px;
      font-weight: 400;
    }
    & .value {
      font-size: 32px;
      line-height: 48px;
      font-weight: 700;
    }
  }
`;

// ----------------------------------------------------------------------

AppTypePieCharts.propTypes = {
  drillDown: PropTypes.func,
  fishNum: PropTypes.number
};

export default function AppTypePieCharts({
  fishNum,
  drillDown,
  type,
  setType,
  typeList,
  setTypeId
}) {
  const [activeIndex, setActiveIndex] = useState(null);
  const [showTooltip, setShowTooltip] = useState(false);
  const [chartWidth, setChartWidth] = useState(0);
  const ChartRef = useRef();
  const { t } = useTranslation();

  const width = useInnerWidth();
  const isXs = width < breakpoints.values.sm;
  const isMd = width >= breakpoints.values.sm && width < breakpoints.values.md;
  const sortTypeList = orderBy(typeList, ['amount'], ['desc']);

  for (let index = 0; index < sortTypeList.length; index += 1) {
    sortTypeList[index].amount = Number(sortTypeList[index].amount);
  }
  const COLORS = useMemo(
    () => [
      'rgba(174,203,84)',
      'rgba(15,82,186)',
      'rgba(0,175,220)',
      'rgba(102,148,246)',
      'rgba(34,154,22)',
      'rgba(255,193,7)',
      'rgba(2,105,148)',
      'rgba(129,216,208)',
      'rgba(255,189,152)',
      'rgba(16,29,107)',
      'rgba(208,174,255)',
      'rgba(124,122,160)'
    ],
    []
  );
  const COLORS_INACTIVE = useMemo(
    () => Array.from(COLORS, (item) => `${item.substring(0, item.length - 1)}, 0.8)`),
    [COLORS]
  );
  const [colorsSet, setColorsSet] = useState(COLORS);
  const RADIAN = Math.PI / 180;

  const renderCustomizedLabel = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    percentage,
    index
  }) => {
    const calcPercentage = Number(percentage);
    if (calcPercentage < 5) {
      const sin = Math.sin(-RADIAN * midAngle);
      const cos = Math.cos(-RADIAN * midAngle);
      const delta = Math.abs(1 / cos) + 10;
      const sx = cx + outerRadius * cos;
      const sy = cy + outerRadius * sin;
      const mx = cx + (outerRadius + delta) * cos;
      const my = cy + (outerRadius + delta) * sin + calcPercentage * 2;
      const ex = mx + Number(cos.toFixed(1)) * 20 - 20;
      const ey = my;
      const textAnchor = cos >= 0 ? 'start' : 'end';
      return (
        <g>
          <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke="#000000" fill="none" />
          <text
            x={ex + (cos >= 0 ? 1 : -1) * 21 - (cos >= 0 ? 18 : -18)}
            y={ey}
            textAnchor={textAnchor}
            fill="#000000"
            // textAnchor={x > cx ? 'start' : 'end'}
            dominantBaseline="central"
            style={{
              fontSize: width < 400 ? '16px' : '22px',
              fontWeight: 700,
              pointerEvents: 'none'
            }}
          >
            {`${fDecimal(calcPercentage)}%`}
          </text>
        </g>
      );
    }
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos((-midAngle * Math.PI) / 180) + 10;
    const y = cy + radius * Math.sin((-midAngle * Math.PI) / 180) + 5;

    if (calcPercentage > 0) {
      const textAnchor = 'middle';
      const dominantBaseline = 'central';
      // if (isXs && width >= 480) {
      //   textAnchor = x > cx ? 'middle' : 'end';
      //   dominantBaseline = 'auto';
      // } else if (isXs && width < 480) {
      //   textAnchor = x > cx ? 'middle' : 'end';
      //   dominantBaseline = 'middle';
      // } else {
      //   textAnchor = x > cx ? 'middle' : 'end';
      //   dominantBaseline = 'middle';
      // }
      return (
        <text
          x={x}
          y={y}
          fill="#ffffff"
          textAnchor={textAnchor}
          dominantBaseline={dominantBaseline}
          style={{
            fontSize: width < 400 ? '16px' : '22px',
            fontWeight: 700,
            pointerEvents: 'none'
          }}
        >
          {`${fDecimal(calcPercentage)}%`}
        </text>
      );
    }
    return null;
  };

  const radian = Math.PI / 180;
  const renderNormalLabel = ({ cx, cy, midAngle, outerRadius, fill, percentage }) => {
    const sin = Math.sin(-radian * midAngle);
    const cos = Math.cos(-radian * midAngle);
    const sx = cx + (outerRadius + 0) * cos;
    const sy = cy + (outerRadius + 0) * sin;
    const mx = cx + (outerRadius + 15) * cos;
    const my = cy + (outerRadius + 15) * sin;
    const ex = mx + (cos >= 0 ? 1 : -1) * 30;
    const ey = my;
    const textAnchor = cos >= 0 ? 'start' : 'end';
    return (
      <g>
        <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={fill} fill="none" />
        <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
        <text
          x={ex + (cos >= 0 ? 1 : -1) * 12}
          y={ey}
          dy={8}
          fill="#0e3688"
          textAnchor={textAnchor}
          style={{ fontSize: '32px', fontWeight: 700, pointerEvents: 'none' }}
        >
          {`${percentage}%`}
        </text>
      </g>
    );
  };

  const renderActiveShape = (props) => {
    const { cx, cy, innerRadius, outerRadius, startAngle, endAngle, fill } = props;
    return (
      <g>
        <Sector
          cx={cx}
          cy={cy}
          innerRadius={innerRadius}
          outerRadius={outerRadius}
          startAngle={startAngle}
          endAngle={endAngle}
          fill={`${fill.substring(0, fill.length - 6)})`}
        />
      </g>
    );
  };

  const onMouseClick = (data, index) => {
    if (type === sortTypeList[index].name) {
      typeSettingHelper('All types', 'All');
    } else {
      typeSettingHelper(sortTypeList[index].name, sortTypeList[index].specie_id);
    }
  };

  const typeSettingHelper = (typeSetter, typeIdSetter) => {
    setTypeId(typeIdSetter);
    setType(typeSetter);
  };

  useEffect(() => {
    let findIndex = -1;
    for (let index = 0; index < sortTypeList.length; index += 1) {
      if (sortTypeList[index].name === type) {
        findIndex = index;
      }
    }
    setActiveIndex(findIndex);
    drillDown(true);
    if (type !== 'All types') {
      setColorsSet(COLORS_INACTIVE);
    } else {
      setColorsSet(COLORS);
    }
  }, [type, COLORS, COLORS_INACTIVE, sortTypeList]);

  const prepareDataForPieChart = useMemo(() => {
    const data = sortTypeList;
    const totalAmount = data.reduce((sum, item) => sum + item.amount, 0);
    const groupedData = [];
    const other = {
      name: 'Other',
      name_th: 'อื่นๆ',
      name_en: 'Other',
      specie_id: 'other',
      percentage: 0,
      amount: 0
    };
    data.forEach((item) => {
      const percentage = (item.amount / totalAmount) * 100;
      if (percentage >= 3) {
        groupedData.push({
          name: item.name,
          percentage: percentage.toFixed(2),
          amount: item.amount,
          name_th: item.name_th,
          name_en: item.name_en,
          specie_id: item.specie_id
        });
      } else {
        other.percentage += percentage;
        other.amount += item.amount;
      }
    });
    other.percentage = other.percentage.toFixed(2);
    if (other.amount > 0) {
      groupedData.push(other);
    }

    return groupedData;
  }, [sortTypeList]);

  const getSize = debounce(() => {
    const width = ChartRef.current.clientWidth;
    const height = ChartRef.current.clientHeight;
    setChartWidth(height === 0 ? width : height);
  }, 500);

  useEffect(() => {
    getSize();
    window.addEventListener('resize', () => {
      getSize();
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const rediusPie = useMemo(() => {
    let calc = chartWidth / 2;
    if (isXs && width >= 480) {
      calc = chartWidth / 2;
    } else if (isXs && width < 480) {
      calc = chartWidth / 2.75;
    }
    return calc;
  }, [chartWidth, isXs, width]);

  const innerRadius = useMemo(() => {
    if (isXs && width >= 480) {
      return 80;
    }
    if (isXs && width < 480) {
      return 60;
    }
    return 80;
  }, [isXs, width]);

  const outerRadius = useMemo(() => rediusPie, [rediusPie]);

  return (
    <>
      <CardHeader
        style={{ textAlign: 'center' }}
        title={
          <Typography
            style={{
              fontStyle: 'normal',
              fontWeight: '700',
              fontSize: 30,
              lineHeight: '30px',
              whiteSpace: 'pre-wrap'
            }}
          >
            {isXs ? t('pie_title').replace('\n', ' ') : t('pie_title')}
          </Typography>
        }
      />
      <ChartWrapperStyle dir="ltr" isMd={isMd}>
        <Grid container spacing={2} style={{ height: '100%' }}>
          <Grid item xs={12} sm={12} md={6}>
            <div ref={ChartRef} className="chart-data">
              <ChartResponsive>
                <PieChart
                  width={chartWidth}
                  height={800}
                  onMouseLeave={() => setShowTooltip(false)}
                >
                  <Pie
                    dataKey="amount"
                    isAnimationActive={false}
                    data={prepareDataForPieChart}
                    cx="50%"
                    cy="50%"
                    innerRadius={innerRadius}
                    outerRadius={outerRadius}
                    labelLine={false}
                    label={renderCustomizedLabel}
                    activeShape={renderActiveShape}
                    activeIndex={activeIndex}
                    onClick={onMouseClick}
                    onMouseEnter={() => setShowTooltip(true)}
                    height={isXs && 800}
                  >
                    {prepareDataForPieChart.map((entry, index) => (
                      <Cell key={`cell-${index}`} fill={colorsSet[index % colorsSet.length]} />
                    ))}
                  </Pie>
                  {/* <Legend
              verticalAlign={width > breakpoints.values.sm ? 'middle' : 'bottom'}
              align={width > breakpoints.values.sm ? 'right' : 'center'}
              layout={width > breakpoints.values.sm ? 'vertical' : 'horizontal'}
              iconType="circle"
              content={
                <PieTypeChartLegend
                  type={type}
                  typeList={sortTypeList}
                  setType={setType}
                  setTypeId={setTypeId}
                  isSm={width < breakpoints.values.sm}
                />
              }
              wrapperStyle={{
                left: isXs ? '16px' : 'calc(100% * 4/7.5)',
                fontSize: isXs && '13px',
                marginTop: isXs && '130px',
                width: '45%'
              }}
            /> */}
                  {showTooltip && (
                    <Tooltip
                      sAnimationActive={false}
                      content={<PieTypeChartTooltip setShowTooltip={setShowTooltip} />}
                      wrapperStyle={{ visibility: 'visible', pointerEvents: 'auto' }}
                    />
                  )}
                </PieChart>
              </ChartResponsive>
              <TotalInChart>
                <span className="value">{fNumber(fishNum)}</span>
                <span className="label">{t('numbers')}</span>
              </TotalInChart>
            </div>
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <div className="legend-data">
              <CustomChartLegend
                activeColors={COLORS}
                inActiveColors={COLORS_INACTIVE}
                type={type}
                typeList={sortTypeList}
                prepareDataForPieChart={prepareDataForPieChart}
                setType={setType}
                setTypeId={setTypeId}
                isSm={width < breakpoints.values.sm}
              />
            </div>
          </Grid>
        </Grid>
      </ChartWrapperStyle>
    </>
  );
}
