import React, { Fragment, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Surface, Symbols } from 'recharts';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

const LI = styled.li`
  width: 100%;
  min-width: 280px;
  font-weight: normal;
  margin-right: 5px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  cursor: pointer;
  max-width: 100%;

  @media only screen and (max-width: 599px) and (min-width: 0px) {
    font-size: 18px;
  }
  @media only screen and (max-width: 899px) and (min-width: 600px) {
    font-size: 18px;
  }
  @media only screen and (max-width: 1199px) and (min-width: 900px) {
    font-size: 20px;
  }
  @media only screen and (max-width: 1535px) and (min-width: 1200px) {
    font-size: 18px;
  }
  @media only screen and (min-width: 1536px) {
    font-size: 22px;
  }
`;

export default function CustomChartLegend(props) {
  const { i18n } = useTranslation();
  const {
    type,
    typeList = [],
    setType,
    setTypeId,
    isSm,
    activeColors,
    inActiveColors,
    prepareDataForPieChart
  } = props;

  const onClickList = (event, index) => {
    if (type !== typeList[index].name) {
      setTypeId(typeList[index].specie_id);
      setType(typeList[index].name);
    } else {
      setTypeId('All');
      setType('All Types');
    }
  };

  const specieName = useCallback((name) => {
    if (name === 'Acanthurus nigricauda') {
      return (
        <i>
          Acanthurus <span style={{ fontStyle: 'normal' }}>cf.</span> nigricauda
        </i>
      );
    }
    return <i>{name}</i>;
  }, []);

  const mappingArray = useMemo(() => {
    const result = prepareDataForPieChart.map((item) => {
      if (item.name !== 'Other') {
        return {
          ...item,
          children: typeList.filter((child) => child.name === item.name)
        };
      }

      const otherChildren = typeList.filter(
        (child) => !prepareDataForPieChart.some((array1Item) => array1Item.name === child.name)
      );

      return {
        ...item,
        children: otherChildren
      };
    });

    return result;
  }, [prepareDataForPieChart, typeList]);

  const fontWeight = useCallback(
    (item, children) => {
      if (type === 'All Species') {
        return 'normal';
      }
      const checkSameType = item.name === type;
      if (checkSameType) {
        return 'bold';
      }
      if (type !== 'All Species' && !checkSameType) {
        const findChild = children.filter((itm) => itm.name === type);
        if (findChild.length > 0) {
          return 'bold';
        }
      }
      return 'normal';
    },
    [type]
  );

  return (
    <ul
      style={{
        listStyle: 'none',
        width: 'min-content'
      }}
    >
      {mappingArray.map((item, index) => (
        <LI
          style={{
            // fontWeight: type === item.name ? 'bold' : 'normal',
            fontWeight: fontWeight(item, item?.children ?? []),
            display: isSm && 'inline-block',
            marginBottom: 16
          }}
          key={`item-${index}`}
          onClick={(event) => onClickList(event, index)}
        >
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Surface
              width={10}
              height={10}
              viewBox="0 0 10 10"
              style={{ marginRight: '5px', borderRadius: '50%' }}
            >
              <Symbols
                cx={5}
                cy={5}
                type="square"
                size={100}
                fill={item.name === type ? activeColors[index] : inActiveColors[index]}
              />
            </Surface>
            <span style={{ fontSize: 24, lineHeight: '24px', fontWeight: '700' }}>
              {item.percentage}%
            </span>
          </div>
          <div style={{ paddingLeft: 16 }}>
            {item?.children.length > 1 && (
              <span style={{ fontSize: 18, fontWeight: 700, color: '#101D6B' }}>Other</span>
            )}
            {item?.children.map((itm, idx) => (
              <div key={itm?.[`name_${i18n.language}`]} style={{ color: '#000000' }}>
                <i>{specieName(itm?.name)} </i>
                <span>({itm?.[`name_${i18n.language}`]})</span>
              </div>
            ))}
          </div>
        </LI>
      ))}
    </ul>
  );
}

CustomChartLegend.propTypes = {
  type: PropTypes.string.isRequired,
  typeList: PropTypes.arrayOf(PropTypes.any).isRequired,
  prepareDataForPieChart: PropTypes.arrayOf(PropTypes.any).isRequired,
  setType: PropTypes.func.isRequired,
  setTypeId: PropTypes.func.isRequired,
  isSm: PropTypes.bool.isRequired,
  activeColors: PropTypes.arrayOf(PropTypes.string).isRequired,
  inActiveColors: PropTypes.arrayOf(PropTypes.string).isRequired
};
