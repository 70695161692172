/* eslint-disable */
import intance from './interceptor.js';

export const ApiGetReport = async (query = '') => {
  return await intance.get('/reports' + query);
};

export const ApiGetFilterAvailableDate = async (date_from, date_to) => {
  return await intance.get('/filters/date?dateFrom=' + date_from + '&dateTo=' + date_to);
};

export const ApiGetFilterAvailableData = async (query) => {
  return await intance.get('/filters' + query);
};

export const ApiGetReportFile = async (query = '') => {
  return await intance.get('/reports/export' + query);
};
