// material
import {
  Box,
  Grid,
  Container,
  Card,
  CardContent,
  Divider,
  styled,
  Stack,
  Typography,
  CircularProgress,
  Paper,
  FormGroup,
  FormControlLabel,
  Switch
} from '@mui/material';
// components
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import Page from 'components/Page';
import AppNumberTypeAmount from 'sections/@dashboard/app/AppNumberTypeAmount';
import AppTypePieCharts from 'sections/@dashboard/app/AppTypePieCharts';
import AppConfidenceChart from 'sections/@dashboard/app/AppConfidentChart';
import AppClassifiedChart from 'sections/@dashboard/app/AppClassifiedChart';
import FilterBar from 'sections/@dashboard/app/FilterBar';
import { addDays, format } from 'date-fns';
import { fDateSDmyToDYmd } from 'utils/formatTime';
import { ApiGetReport } from 'api';
import useInnerWidth from 'hooks/useInnerWidth';
import breakpoints from 'theme/breakpoints';
import { useTranslation } from 'react-i18next';
import { useParams, useNavigate } from 'react-router';
import AppRemarkConfidence from 'sections/@dashboard/app/AppRemarkConfidence';

// ----------------------------------------------------------------------
const DRAWER_WIDTH = 350;

const CardShadowStyle = styled(Card)(({ theme }) => ({
  boxShadow: '0px 16px 32px -4px rgba(145, 158, 171, 0.12)',
  backgroundColor: '#D4EEF9'
}));

const BoxStyle = styled(Box)(({ theme }) => ({
  backgroundColor: 'transparent',
  ...theme.typography.body2,
  position: 'absolute',
  top: '50%',
  // left: `calc(50% + ${DRAWER_WIDTH / 2}px)`,
  left: `50%`,
  [theme.breakpoints.down('lg')]: {
    left: `50%`
  },
  transform: 'translate(-50%, -50%)'
}));

const paperCoverStyle = {
  backgroundColor: 'transparent',
  opacity: '0.3',
  zIndex: 10000,
  filter: 'blur(2px)'
};

export default function DashboardApp() {
  const { t, i18n } = useTranslation();
  const { language } = useParams();
  const navigate = useNavigate();
  const [startDate, setStartDate] = useState(format(addDays(new Date(), -30), 'd/M/yyyy'));
  const [endDate, setEndDate] = useState(format(new Date(), 'd/M/yyyy'));
  const [type, setType] = useState('All Species');
  const [typeId, setTypeId] = useState('All');
  const [isDrillDown, setIsDrillDown] = useState(false);
  const [isZero, setIsZero] = useState(false);
  const [loading, setLoading] = useState(true);

  const [timeRange, setTimeRange] = useState('');
  const [location, setLocation] = useState('');

  // 1st section
  const [fishNum, setFishNum] = useState(0);
  const [typeTotal, setTypeTotal] = useState(0);

  // 2nd section
  const [typeList, setTypeList] = useState([]);

  // 3rd section
  const [avgConfidence, setAvgConfidence] = useState(0);
  const [classified, setClassified] = useState(0);
  const [numberByDate, setNumberByDate] = useState([]);
  const width = useInnerWidth();
  const isXs = width < breakpoints.values.sm;
  const ismd = width < breakpoints.values.lg;
  const pieChartRef = useRef(null);

  useEffect(() => {
    setLoading(true);
    let queryParam = `?dateFrom=${fDateSDmyToDYmd(startDate)}&`;
    queryParam = `${queryParam}dateTo=${fDateSDmyToDYmd(endDate)}&`;
    if (timeRange) {
      queryParam += `time=${timeRange}&`;
    }
    if (location) {
      queryParam += `location=${location}&`;
    }
    queryParam =
      typeId === 'All' || typeId === undefined ? queryParam : `${queryParam}&type=${typeId}&`;
    queryParam = `${queryParam}confidence=0.95&`;
    queryParam = queryParam.substring(0, queryParam.length - 1);
    ApiGetReport(queryParam).then((response) => {
      setFishNum(type === 'All types' ? response.data.data.total : response.data.data.classified);
      setTypeTotal(response.data.data.type_length);
      if (response.data.data.type_all) {
        setTypeList(response.data.data.type_all);
      }
      setAvgConfidence(
        response.data.data.avg_confidence ? response.data.data.avg_confidence.toFixed(2) : 0
      );
      setClassified(response.data.data.classified);
      setNumberByDate(response.data.data.date);
      // eslint-disable-next-line no-unused-expressions
      response.data.data.total === 0 ? setIsZero(true) : setIsZero(false);
      setLoading(false);
    });
  }, [startDate, endDate, type, typeId, timeRange, location]);

  useEffect(() => {
    if (type !== 'All types') {
      setIsDrillDown(true);
    } else {
      setIsDrillDown(false);
    }
  }, [type]);

  const dividerStyledXS = {
    orientation: 'verical',
    variant: 'fullWidth',
    sx: { borderWidth: '0.5px', height: '100%', borderColor: '#2AAAE2' }
  };
  const dividerStyle = isXs ? dividerStyledXS : { variant: 'middle' };

  const cardStyleXS = {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center'
  };
  const cardShadowStyleXs = isXs ? cardStyleXS : {};
  const confidenceHeight = useMemo(() => {
    if (isXs) {
      return '100%';
    }

    if (pieChartRef.current) {
      return pieChartRef.current.clientHeight;
    }

    return '100%';
  }, [isXs]);

  useEffect(() => {
    let initLang = 'en';
    if (language && language.toLowerCase() === 'th') {
      initLang = 'th';
    }
    i18n.changeLanguage(initLang);
  }, [i18n, language]);

  return (
    <Page
      title="Dashboard | Aquatic monitoring system"
      style={{
        height: '100vh',
        overflow: 'scroll',
        paddingTop: 'unset'
        // paddingLeft: ismd ? undefined : 'calc(180px - 16px)',
        // paddingRight: ismd ? undefined : 'calc(180px - 16px)'
      }}
    >
      <Container
        sx={{
          marginLeft: 'auto',
          marginRight: 'auto',
          marginBottom: '30px'
        }}
        maxWidth={false}
      >
        <Box style={{ marginTop: '62px' }}>
          <FilterBar
            type={type}
            typeId={typeId}
            setType={setType}
            setTypeId={setTypeId}
            startDate={startDate}
            setStartDate={setStartDate}
            endDate={endDate}
            setEndDate={setEndDate}
            timeRange={timeRange}
            setTimeRange={setTimeRange}
            location={location}
            setLocation={setLocation}
          />
        </Box>
        <Box style={{ textAlign: 'center', margin: '24px 0px' }}>
          <Typography
            style={{
              fontSize: isXs ? '7vw' : '40px',
              fontWeight: 'bold',
              fontStyle: 'normal',
              lineHeight: '36px',
              color: '#0E3688'
            }}
          >
            {t('header')}
          </Typography>
        </Box>
        <Paper
          sx={{
            ...((isZero || loading) && paperCoverStyle),
            backgroundColor: 'transparent'
          }}
        >
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12} md={12}>
              <AppNumberTypeAmount
                fishNum={fishNum}
                typeNum={typeTotal}
                avgConfidence={avgConfidence}
              />
            </Grid>
            <Grid item xs={12}>
              <CardShadowStyle>
                <AppRemarkConfidence />
              </CardShadowStyle>
            </Grid>
            <Grid item xs={12} ref={pieChartRef}>
              <CardShadowStyle sx={{ height: 'max-content' }}>
                <AppTypePieCharts
                  fishNum={fishNum}
                  drillDown={setIsDrillDown}
                  type={type}
                  setType={setType}
                  typeList={typeList}
                  setTypeId={setTypeId}
                />
              </CardShadowStyle>
            </Grid>

            {/* <Grid item xs={12} md={4} lg={4}>
              <CardShadowStyle
                sx={{
                  height: confidenceHeight,
                  ...cardShadowStyleXs
                }}
              >
                <AppConfidenceChart avgConfidence={avgConfidence} />
              </CardShadowStyle>
            </Grid> */}
          </Grid>
        </Paper>
        <BoxStyle sx={{ visibility: `${!isZero && !loading && 'hidden'}` }}>
          <Stack direction="column" justifyContent="center" alignItems="center" spacing={2}>
            {loading ? (
              <CircularProgress />
            ) : (
              <>
                <Typography variant="subtitle1">{t('causion1')}</Typography>
                <Typography variant="subtitle1">{t('causion2')}</Typography>
              </>
            )}
          </Stack>
        </BoxStyle>
      </Container>
    </Page>
  );
}
