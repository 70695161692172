import { useEffect, useState } from 'react';
import { addDays, format, lightFormat } from 'date-fns';
import { Button, Menu } from '@mui/material';
import DateRange from 'react-date-range/dist/components/DateRange';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { ApiGetFilterAvailableDate } from 'api';
import './dateRange.css';
import breakpoints from 'theme/breakpoints';
import useInnerWidth from 'hooks/useInnerWidth';

export default function BasicDateRangePicker({
  className,
  icon,
  startDate,
  setStartDate,
  endDate,
  setEndDate
}) {
  const [state, setState] = useState([
    {
      startDate: addDays(new Date(), -30),
      endDate: new Date(),
      key: 'selection'
    }
  ]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [availableDate, setAvailableDate] = useState([]);
  const width = useInnerWidth();

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSelect = (item) => {
    const selection = [item.selection];
    setState(selection);
    setStartDate(format(new Date(item.selection.startDate), 'dd/MM/yyyy'));
    setEndDate(format(new Date(item.selection.endDate), 'dd/MM/yyyy'));
  };

  const handleChangeMonth = (item) => {
    const firstDate = item.toLocaleString().split(',')[0];
    const firstDateSplit = firstDate.split('/');
    const firstDateFormat = `${firstDateSplit[2]}-${firstDateSplit[0]}-01`;
    const lastDate = new Date(firstDateSplit[2], firstDateSplit[0], 0).getDate();
    const lastDateFormat = `${firstDateSplit[2]}-${firstDateSplit[0]}-${lastDate}`;
    ApiGetFilterAvailableDate(firstDateFormat, lastDateFormat)
      .then((response) => {
        const avd = [];
        for (let i = 0; i < response.data.data.length; i += 1) {
          avd.push(response.data.data[i].date);
        }
        setAvailableDate(avd);
      })
      .catch((error) => console.error(error));
  };

  const isAvailableDate = (day) => {
    const lightDay = lightFormat(day, 'yyyy-MM-dd');
    if (availableDate.includes(lightDay)) {
      return true;
    }
    return false;
  };

  const customDayContent = (day) => {
    let extraDot = null;
    let dateRender = <span>{format(day, 'd')}</span>;
    if (isAvailableDate(day)) {
      extraDot = (
        <div
          style={{
            height: '2px',
            width: '2px',
            borderRadius: '100%',
            background: '#2AAAE2',
            position: 'absolute',
            bottom: 2,
            left: '50%'
          }}
        />
      );
      dateRender = <span style={{ fontWeight: 'bold' }}>{format(day, 'd')}</span>;
    }
    return (
      <div>
        {dateRender}
        {extraDot}
      </div>
    );
  };

  useEffect(() => {
    handleChangeMonth(new Date(new Date().getTime() - 30 * 24 * 60 * 60 * 1000));
  }, []);

  return (
    <div>
      <Button
        id="basic-button"
        className={className}
        color="inherit"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        startIcon={icon}
      >
        <span
          className="button-select-date"
          style={{ fontSize: width < breakpoints.values.sm ? '4.5vw' : '24px' }}
        >
          {startDate.split('/')[0]}/{Number(startDate.split('/')[1])}/{startDate.split('/')[2]}
          {' - '}
          {endDate.split('/')[0]}/{Number(endDate.split('/')[1])}/{endDate.split('/')[2]}
        </span>
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button'
        }}
      >
        <DateRange
          onChange={handleSelect}
          moveRangeOnFirstSelection={false}
          ranges={state}
          showDateDisplay={false}
          onShownDateChange={handleChangeMonth}
          dayContentRenderer={customDayContent}
          rangeColors={['rgba(24, 144, 255, 0.08)']}
        />
      </Menu>
    </div>
  );
}
