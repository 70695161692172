import { Grid, Typography, Stack } from '@mui/material';
import useInnerWidth from 'hooks/useInnerWidth';
import breakpoints from 'theme/breakpoints';
import { useTranslation } from 'react-i18next';

export default function AppRemarkConfidence() {
  const width = useInnerWidth();
  const { t } = useTranslation();

  const isXs = width < breakpoints.values.sm;

  return (
    <div style={{ height: '100%', textAlign: 'center' }}>
      <div
        style={{
          height: 'calc(100% - 48px)',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center'
          // justifyContent: 'space-between'
        }}
      >
        <Grid container>
          <Grid item xs={12}>
            <Typography
              variant="h4"
              style={{
                padding: '16px 8px',
                fontStyle: 'normal',
                fontWeight: '700',
                fontSize: '18px',
                lineHeight: '18px',
                color: '#0e3688',
                textAlign: 'center',
                whiteSpace: 'pre-wrap',
                boxSizing: 'border-box'
              }}
            >
              {t('gauge_desc')}
            </Typography>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}
