// material
import {
  Box,
  Card,
  Divider,
  Grid,
  Typography,
  styled,
  Tooltip,
  tooltipClasses
} from '@mui/material';
// utils
import { fNumber } from 'utils/formatNumber';
// component
import { DiagramIcon, FishIcon, TurtleIcon } from 'components/icon/DashboardIcon';
import useInnerWidth from 'hooks/useInnerWidth';
import breakpoints from 'theme/breakpoints';
import AnimalMainIcon from 'assets/icons/animals-main.svg';
import FishesIcon from 'assets/icons/fishes.svg';
import { useTranslation } from 'react-i18next';
import AppConfidenceChart from './AppConfidentChart';
// ----------------------------------------------------------------------

const RootStyle = styled(Card)(({ theme }) => ({
  boxShadow: 'none',
  textAlign: 'center',
  padding: 0,
  // padding: theme.spacing(5, 0),
  color: theme.palette.primary.darker,
  backgroundColor: '#D4EEF9',
  height: '100%'
}));

const IconWrapperStyle = styled('div')(({ theme, width = undefined, height = undefined }) => ({
  margin: 'auto',
  display: 'flex',
  alignItems: 'center',
  width: width || theme.spacing(8),
  height: height || theme.spacing(8),
  justifyContent: 'center',
  color: theme.palette.primary.dark
}));

const TypographyBottomStyle = styled(Typography)(({ theme }) => ({
  fontSize: '12px'
}));

const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.black
  }
}));

// ----------------------------------------------------------------------

export default function AppNumberTypeAmount({ fishNum, typeNum, avgConfidence }) {
  const width = useInnerWidth();
  const { t } = useTranslation();
  const iconWidth = width > breakpoints.values.sm ? 80 : 30;

  const isXs = width < breakpoints.values.sm;

  const detailPadding = {
    paddingTop: '24px',
    paddingBottom: '24px',
    alignItems: 'center'
  };

  const contentStyle = {
    paddingTop: isXs ? '16px' : '24px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '24px'
  };

  const contentFontStyle = {
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '30px',
    lineHeight: '22px'
  };

  const totalStyle = {
    textAlign: 'center',
    fontSize: isXs ? '32px' : '64px'
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} md={4}>
          <RootStyle>
            <Grid item xs>
              <Box sx={{ flexGrow: 1, px: isXs ? 1 : 3 }}>
                <Grid
                  container
                  spacing={{
                    xs: 1
                    // md: '32px'
                  }}
                  justifyContent="center"
                  {...detailPadding}
                >
                  <Grid item xs={24} style={{ display: 'flex', flexDirection: 'column' }}>
                    <div style={{ height: isXs ? '50px' : undefined }}>
                      <Typography
                        style={{
                          textAlign: 'center',
                          width: '100%'
                        }}
                      >
                        <b style={contentFontStyle}>{t('numbers')}</b>
                      </Typography>
                    </div>
                    <div style={contentStyle}>
                      <img
                        className="icon-box"
                        alt="animals"
                        src={AnimalMainIcon}
                        width={isXs ? iconWidth : undefined}
                        height={isXs ? iconWidth : '72px'}
                      />

                      <div>
                        <BootstrapTooltip title="Estimated by ROVULA Artificial Intelligence (AI) Model">
                          <Typography className="box-value" variant="h3" style={totalStyle}>
                            {fNumber(fishNum)}
                          </Typography>
                        </BootstrapTooltip>
                      </div>
                    </div>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          </RootStyle>
        </Grid>
        <Grid item xs={12} md={8}>
          <RootStyle>
            <Grid container style={{ height: '100%' }}>
              <Grid item xs={12} md={6} className="box-left">
                <Box sx={{ flexGrow: 1, px: isXs ? 1 : 3 }}>
                  <Grid
                    container
                    spacing={{
                      xs: 1
                      // md: '32px'
                    }}
                    justifyContent="center"
                    {...detailPadding}
                  >
                    <Grid item xs={24} style={{ display: 'flex', flexDirection: 'column' }}>
                      <div style={{ height: isXs ? '50px' : undefined }}>
                        <Typography
                          style={{
                            textAlign: 'center',
                            width: '100%'
                          }}
                        >
                          <b style={contentFontStyle}>{t('number_species')}</b>
                        </Typography>
                      </div>
                      <div style={contentStyle}>
                        <img
                          className="icon-box"
                          alt="animals"
                          src={FishesIcon}
                          width={isXs ? iconWidth : undefined}
                          height={isXs ? iconWidth : '72px'}
                        />

                        <div>
                          <BootstrapTooltip title="Estimated by ROVULA Artificial Intelligence (AI) Model">
                            <Typography className="box-value" variant="h3" style={totalStyle}>
                              {fNumber(typeNum)}
                            </Typography>
                          </BootstrapTooltip>
                        </div>
                      </div>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
              <Grid item xs={12} md={6} className="box-right">
                <Box sx={{ flexGrow: 1, px: isXs ? 1 : 3 }}>
                  <Grid
                    container
                    spacing={{
                      xs: 1
                      // md: '32px'
                    }}
                    justifyContent="center"
                    {...detailPadding}
                  >
                    <Grid item xs={24} style={{ display: 'flex', flexDirection: 'column' }}>
                      <AppConfidenceChart avgConfidence={avgConfidence} />
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
            </Grid>
          </RootStyle>
        </Grid>
      </Grid>
      {/* <Grid container>
        <Grid item xs>
          <Box sx={{ flexGrow: 1, overflow: 'hidden', px: 3 }}>
            <Grid container spacing={2} justifyContent="center">
              <Grid item>
                <TypographyBottomStyle>
                  <span style={{ color: 'red' }}>*</span> Estimated by our AI model
                </TypographyBottomStyle>
              </Grid>
            </Grid>
          </Box>
        </Grid>
        <Grid item xs />
      </Grid> */}
    </>
  );
}
