import { Alert, Button, CircularProgress, Grid, Stack, Typography } from '@mui/material';
import { CalendarIcon, FilterIcon, DownloadRoundIcon } from 'components/icon/DashboardIcon';
import BasicDateRangePicker from 'components/BasicDateRangePicker';
import BasicMenu from 'components/BasicMenu';
import { useCallback, useEffect, useState } from 'react';
import { ApiGetFilterAvailableData, ApiGetReport, ApiGetReportFile } from 'api';
import { fDateSDmyToDYmd, fDateDYmdToDDmy } from 'utils/formatTime';
import FishIcon from 'assets/icons/fish.svg';
import TurtleIcon from 'assets/icons/turtle.svg';
import AnimalsIcon from 'assets/icons/animals.svg';
import DownloadSvgIcon from 'assets/icons/download.svg';
import { saveAs } from 'file-saver';
import swal from 'sweetalert2';
import { LoadingButton } from '@mui/lab';
import breakpoints from 'theme/breakpoints';
import useInnerWidth from 'hooks/useInnerWidth';
import { useTranslation } from 'react-i18next';
import BasicTimeRangePicker from 'components/BasicTimeRangePicker';
import BasicLocationPicker from 'components/BasicLocationPicker';
import styled from 'styled-components';

const StackFilter = styled(Stack)`
  @media only screen and (max-width: 375px) and (min-width: 0px) {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    & > .filter-date-range {
      width: 60%;
    }
    & > .filter-time-range {
      width: 35%;
    }
    & > .filter-species {
      width: 50%;
    }
    & > .filter-location {
      width: 35%;
    }
    & > .space {
      width: 50%;
    }
    & > .report {
      width: 35%;
    }
  }
  @media only screen and (max-width: 599px) and (min-width: 376px) {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    & > .filter-date-range {
      width: 60%;
    }
    & > .filter-time-range {
      width: 35%;
    }
    & > .filter-species {
      width: 60%;
    }
    & > .filter-location {
      width: 35%;
    }
    & > .space {
      width: 50%;
    }
    & > .report {
      width: 35%;
    }
  }
  @media only screen and (max-width: 899px) and (min-width: 600px) {
    display: flex;
    width: calc(100% - 116px);
    flex-wrap: wrap;
    & > .filter-date-range {
      width: 55%;
    }
    & > .filter-time-range {
      width: 40%;
    }
    & > .filter-species {
      width: 55%;
    }
    & > .filter-location {
      width: 40%;
    }
    & > .space {
      width: 55%;
    }
    & > .report {
      width: 40%;
    }
  }
`;

export default function FilterBar(props) {
  const { t } = useTranslation();
  const {
    type,
    typeId,
    setType,
    setTypeId,
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    timeRange,
    setTimeRange,
    location,
    setLocation
  } = props;
  const [typeItems, setTypeItems] = useState([{ name: 'All Species', id: 'All' }]);
  const [isLoadingReport, setIsLoadingReport] = useState(false);
  const [locationList, setLocationList] = useState([]);
  const width = useInnerWidth();

  const handleLoadingReportClose = () => setIsLoadingReport(false);
  const handleLoadingReportOpen = () => setIsLoadingReport(true);

  useEffect(() => {
    let query = `?dateFrom=${fDateSDmyToDYmd(startDate)}&dateTo=${fDateSDmyToDYmd(
      endDate
    )}&confidence=0.95`;
    query = typeId === 'All' || typeId === undefined ? query : `${query}&type=${typeId}`;
    ApiGetFilterAvailableData(query)
      .then((response) => {
        const { location, specie } = response.data.data;
        setLocationList(location);
        const fixSpecies = specie.map((spe) => {
          if (spe.name === 'Acanthurus nigricauda') {
            return {
              ...spe,
              name: 'Acanthurus cf. nigricauda'
            };
          }
          return spe;
        });
        setTypeItems([{ name: 'All Species', id: 'All' }].concat(fixSpecies));
        setType(t('All Species'));
        setTypeId('All');
      })
      .catch((error) => console.log(error));
  }, [startDate, endDate]);

  const handleExport = () => {
    handleLoadingReportOpen();
    let queryFullParam = `?dateFrom=${fDateSDmyToDYmd(startDate)}&dateTo=${fDateSDmyToDYmd(
      endDate
    )}`;
    queryFullParam =
      typeId === 'All' || typeId === undefined
        ? queryFullParam
        : `${queryFullParam}&type=${typeId}`;

    ApiGetReportFile(queryFullParam)
      .then((response) => {
        if (response.data) {
          const urlParams = new URLSearchParams(queryFullParam);

          const dateFrom = urlParams.get('dateFrom');

          const dateTo = urlParams.get('dateTo');

          const filename = `Report_${fDateDYmdToDDmy(dateFrom)}_to_${fDateDYmdToDDmy(dateTo)}.csv`;
          const blob = new Blob([`\uFEFF${response.data}`], {
            type: 'text/csv;charset=utf-8'
          });

          saveAs(blob, filename);
        } else {
          swal.fire({ icon: 'error', text: 'No data to export' });
        }

        handleLoadingReportClose();
      })
      .catch((err) => {
        swal.fire({ icon: 'error', text: err.message });
        handleLoadingReportClose();
      });
  };

  const typeName = useCallback((name) => name, []);

  return (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="space-between"
      sx={{
        // mb: 5,
        padding: '1px 16px',
        backgroundColor: '#2AAAE2',
        borderRadius: '24px 0px',
        color: 'white'
      }}
    >
      <StackFilter
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        spacing={1}
        flexShrink={0}
        sx={{ my: 1 }}
      >
        <div className="filter-date-range">
          <BasicDateRangePicker
            className="button-select-date"
            icon={<CalendarIcon width={20} mar />}
            startDate={startDate}
            setStartDate={setStartDate}
            endDate={endDate}
            setEndDate={setEndDate}
          />
        </div>
        <div className="filter-time-range">
          <BasicTimeRangePicker timeRange={timeRange} setTimeRange={setTimeRange} />
        </div>
        <div className="filter-species">
          <BasicMenu
            className="button-select-species"
            topic={
              <Typography style={{ fontSize: width < breakpoints.values.sm ? '4.5vw' : '24px' }}>
                {typeId === 'All'
                  ? t(`species_${(typeId || 'all').toLowerCase()}`)
                  : typeName(type)}
              </Typography>
            }
            icon={
              <span style={{ display: 'inline-flex' }}>
                <img
                  alt="animals-icon"
                  style={{ width: '20px', height: '20px' }}
                  src={AnimalsIcon}
                />
              </span>
            }
            items={typeItems}
            setItem={setType}
            setItemId={setTypeId}
          />
        </div>
        <div className="filter-location">
          <BasicLocationPicker list={locationList} location={location} setLocation={setLocation} />
        </div>
        {width < breakpoints.values.sm && (
          <>
            <div className="space" />
            <div className="report">
              <LoadingButton
                disabled={isLoadingReport}
                variant="text"
                style={{
                  backgroundColor: '#AECB54',
                  color: '#212B36',
                  gap: 8,
                  padding: '8px 16px',
                  height: '28px',
                  width: 'fit-content'
                }}
                onClick={handleExport}
              >
                <img alt="fish-icon" src={DownloadSvgIcon} />
                <Typography
                  style={{
                    fontSize: '20px',
                    fontWeight: '700',
                    textWrap: 'nowrap'
                  }}
                >
                  {!isLoadingReport ? t('report_button') : 'Loading...'}
                </Typography>
              </LoadingButton>
            </div>
          </>
        )}
      </StackFilter>
      {width >= breakpoints.values.sm && (
        <Stack
          direction="row"
          justifyContent="space-between"
          spacing={1}
          flexShrink={0}
          sx={{ my: 1 }}
        >
          <LoadingButton
            disabled={isLoadingReport}
            variant="text"
            style={{
              backgroundColor: '#AECB54',
              color: '#212B36',
              gap: 8,
              padding: '8px 16px',
              height: '36px'
            }}
            onClick={handleExport}
          >
            <img alt="fish-icon" src={DownloadSvgIcon} />
            <Typography
              style={{
                fontWeight: '700',
                fontSize: width < breakpoints.values.sm ? '6.5vw' : '24px',
                textWrap: 'nowrap'
              }}
            >
              {!isLoadingReport ? t('report_button') : 'Loading...'}
            </Typography>
          </LoadingButton>
        </Stack>
      )}
    </Stack>
  );
}
