import { useState, useEffect } from 'react';
import { alpha, Avatar, Box, styled, Typography } from '@mui/material';
import { fNumber } from 'utils/formatNumber';

const TooltipStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(1, 1),
  backgroundColor: alpha(theme.palette.background.default, 0.72),
  borderRadius: '10px',
  overflow: 'hidden'
}));

export default function PieTypeChartTooltip({ setShowTooltip, ...rest }) {
  const [payload, setPayload] = useState(rest.payload);

  const imageFileMap = {
    'Abudefduf vaigiensis': 'Abudefduf_vaigiensis.jpeg',
    'Acanthurus nigricauda': 'Acanthurus_nigricauda.jpeg',
    'Caranx ignobilis': 'Caranx_ignobilis.jpeg',
    'Elagatis bipinnulata': 'Elagatis_bipinnulata.jpeg',
    'Kyphosus vaigiensis': 'Kyphosus_vaigiensis.jpeg',
    'Platax teira': 'Platax_teira.jpeg',
    'Thalassoma lunare': 'Thalassoma_lunare.jpeg',
    Stingray: 'Stingray.jpeg',
    Turtle: 'Turtle.jpeg',
    Shark: 'Shark.webp',
    Whale: 'Whale.webp',
    Dolphin: 'Dolphin.jpeg',
    Seal: 'Seal.jpeg',
    'Sea Lion': 'Sea_Lion.jpeg',
    Seahorse: 'Seahorse.jpeg',
    Other: 'Other.svg'
  };

  useEffect(() => {
    // eslint-disable-next-line no-unused-expressions
    rest.payload.length && setPayload(rest.payload);
  }, [rest.payload]);

  return payload.length ? (
    <div
      onMouseLeave={() => setShowTooltip(false)}
      onMouseMove={(e) => e.stopPropagation()}
      style={{
        borderRadius: '4px',
        boxShadow: '0 1px 4px rgba(0,0,0,0.3)'
      }}
    >
      <Box sx={{ mb: 5 }}>
        <TooltipStyle>
          <Avatar src={`/static/Fish_species/${imageFileMap[payload[0].name]}`} variant="rounded" />
          <Box sx={{ ml: 2 }}>
            <i>
              <Typography>{`${payload[0].name}`}</Typography>
              <Typography sx={{ fontWeight: 'bold' }}>{`${fNumber(payload[0].value)}`}</Typography>
            </i>
          </Box>
        </TooltipStyle>
      </Box>
    </div>
  ) : null;
}
