import { Typography } from '@mui/material';
import React, { useMemo } from 'react';
import BasicMenu from 'components/BasicMenu';
import { ClockIcon } from 'components/icon/DashboardIcon';
import PropTypes from 'prop-types';
import useInnerWidth from 'hooks/useInnerWidth';
import breakpoints from 'theme/breakpoints';
import { useTranslation } from 'react-i18next';

function BasicTimeRangePicker(props) {
  const { t } = useTranslation();
  const width = useInnerWidth();
  const { timeRange, setTimeRange } = props;

  const range = useMemo(
    () => [
      { id: '', name: t('time_range') },
      { id: '00:01-06:00', name: '00.01 - 06.00' },
      { id: '06:01-12:00', name: '06.01 - 12.00' },
      { id: '12:01-18:00', name: '12.01 - 18.00' },
      { id: '18:01-00:00', name: '18.01 - 00.00' }
    ],
    [t]
  );

  return (
    <div>
      <BasicMenu
        className="button-select-times"
        topic={
          <Typography style={{ fontSize: width < breakpoints.values.sm ? '4.5vw' : '24px' }}>
            {range.find((t) => t.id === timeRange).name}
          </Typography>
        }
        icon={<ClockIcon width={20} mar />}
        items={range}
        setItem={() => null}
        setItemId={(id) => setTimeRange(id)}
      />
    </div>
  );
}

BasicTimeRangePicker.propTypes = {
  timeRange: PropTypes.string.isRequired,
  setTimeRange: PropTypes.func.isRequired
};

export default BasicTimeRangePicker;
