// material
import { Stack, Grid, Typography, styled, alpha, Box } from '@mui/material';
//
import { fNumber } from 'utils/formatNumber';
import {
  LineChart,
  Line,
  XAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  PieChart,
  Pie,
  Cell,
  Label,
  YAxis
} from 'recharts';
import { fDateOnly } from 'utils/formatTime';
import useInnerWidth from 'hooks/useInnerWidth';
import breakpoints from 'theme/breakpoints';

// ----------------------------------------------------------------------

const TooltipStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(1, 1),
  backgroundColor: theme.palette.background.default,
  overflow: 'hidden',
  background: 'rgba(255, 255, 255, 0.48)',
  borderRadius: '4px',
  boxShadow: '0 1px 4px rgba(0,0,0,0.3)'
}));

function CustomTooltip({ active, payload, label }) {
  if (active && payload && payload.length) {
    return (
      <Box>
        <TooltipStyle>
          <Typography>
            {label.replace(/(..).(..).(....)/, '$2/$1/$3')} :{' '}
            <span style={{ fontWeight: 'bold' }}>{payload[0].value}</span>
          </Typography>
        </TooltipStyle>
      </Box>
    );
  }

  return null;
}

export default function AppClassifiedChart({ isDrillDown, classified, total, numberByDate }) {
  const width = useInnerWidth();
  const isXs = width < breakpoints.values.sm;

  const donutData = [
    { name: 'confidence', value: Number(classified) },
    { name: 'non-confidence', value: Number(total - classified) }
  ];

  for (let index = 0; index < numberByDate.length; index += 1) {
    numberByDate[index].amount = Number(numberByDate[index].amount);
    numberByDate[index].date = fDateOnly(numberByDate[index].date);
  }
  const COLORS = ['#0E3688', 'rgba(0.15, 0.08, 0.00, 0.14)'];

  return (
    <Stack
      height={isXs ? '133px' : '220px'}
      direction="row"
      justifyContent="space-around"
      alignItems="center"
      sx={{ margin: '0 auto', flex: 1 }}
    >
      <>
        <ResponsiveContainer width={isXs ? 76 : 116} height="100%">
          <PieChart width={76} height={76}>
            <Pie
              data={donutData}
              cx="50%"
              cy="50%"
              innerRadius={isXs ? 15 : 29}
              outerRadius={isXs ? 25 : 37}
              fill="#8884d8"
              dataKey="value"
              startAngle={90}
              endAngle={-270}
            >
              {donutData.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
              ))}
              {!isXs && (
                <Label
                  style={{ fontSize: 24, fontWeight: 700 }}
                  value={`${fNumber((classified / total) * 100)}%`}
                  position="center"
                />
              )}
            </Pie>
          </PieChart>
        </ResponsiveContainer>
        <Grid container direction="column">
          <Typography variant="h4" style={{ textAlign: 'center' }}>
            {fNumber(donutData[0].value)}
          </Typography>
          <div style={{ display: 'flex', justifyContent: 'center', marginTop: 8 }}>
            <ul className="list-classified" style={{ height: isXs ? '60px' : undefined }}>
              <li style={{ color: '#252260' }}>
                <Typography sx={{ fontSize: '24px', lineHeight: '24px' }}>
                  <b>Identified</b>
                </Typography>
              </li>
              <li style={{ color: '#637381' }}>
                <Typography>
                  <b>Unknown</b>
                </Typography>
              </li>
            </ul>
          </div>
        </Grid>
      </>
    </Stack>
  );
}
