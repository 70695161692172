// material
import { GlobalStyles as GlobalThemeStyles, useTheme } from '@mui/material';
import '@fontsource/plus-jakarta-sans';
// DBHelvethaicaX

// ----------------------------------------------------------------------

export default function GlobalStyles() {
  const theme = useTheme();

  return (
    <GlobalThemeStyles
      styles={{
        '*': {
          margin: 0,
          padding: 0,
          boxSizing: 'border-box'
        },
        html: {
          width: '100%',
          height: '100%',
          WebkitOverflowScrolling: 'touch'
        },
        body: {
          width: '100%',
          height: '100%',
          fontFamily: 'DB Helvethaica X',
          backgroundColor: '#2AAAE2'
        },
        '#root': {
          width: '100%',
          height: '100%'
        },
        input: {
          '&[type=number]': {
            MozAppearance: 'textfield',
            '&::-webkit-outer-spin-button': {
              margin: 0,
              WebkitAppearance: 'none'
            },
            '&::-webkit-inner-spin-button': {
              margin: 0,
              WebkitAppearance: 'none'
            }
          },
          '&::placeholder': {
            color: '#74CAFF',
            opacity: 1
          }
        },
        textarea: {
          '&::-webkit-input-placeholder': {
            color: theme.palette.text.disabled
          },
          '&::-moz-placeholder': {
            opacity: 1,
            color: theme.palette.text.disabled
          },
          '&:-ms-input-placeholder': {
            color: theme.palette.text.disabled
          },
          '&::placeholder': {
            color: theme.palette.text.disabled
          }
        },

        img: { display: 'block', maxWidth: '100%' },

        // Lazy Load Img
        '.blur-up': {
          WebkitFilter: 'blur(5px)',
          filter: 'blur(5px)',
          transition: 'filter 400ms, -webkit-filter 400ms'
        },
        '.blur-up.lazyloaded ': {
          WebkitFilter: 'blur(0)',
          filter: 'blur(0)'
        },
        '.swal2-container': {
          zIndex: 5000
        },
        '.swal2-styled.swal2-confirm': {
          backgroundColor: '#2AAAE2'
        },
        '.swal2-styled.swal2-cancel': {
          backgroundColor: 'white',
          border: 'solid 1px #e7e7e7',
          color: 'black'
        },
        '.swal2-title': {
          fontSize: '24px'
        },
        '.swal2-popup': {
          borderRadius: '16px'
        },
        '.no-border': {
          border: '0 !important;'
        }
      }}
    />
  );
}
