import axios from 'axios';
import Config from 'config';

export const requestInterceptors = [
  (config) => {
    // config.headers['Access-Control-Allow-Origin'] = true;
    const token = Config.getValue('REACT_APP_ACCESS_TOKEN');
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    return config;
  },
  (error) => {
    Promise.reject(error);
  }
];

export const responseInterceptors = [
  (response) => response,
  (error) => {
    if (error && error.response) {
      if (error.response.status === 401) {
        if (process.env.REACT_APP_NODE_ENVIROMENT !== 'local') {
          window.location.reload();
        }
        window.location.href = '/';

        return Promise.reject(error);
      }
    }

    return Promise.reject(error);
  }
];

const intance = axios.create({
  timeout: 60000,
  baseURL: Config.getValue('REACT_APP_BACKEND_URL') || '/api',
  headers: {
    ContentType: 'application/json'
  }
});

intance.interceptors.request.use(...requestInterceptors);
intance.interceptors.response.use(...responseInterceptors);

export default intance;
