import { PersistGate } from 'redux-persist/lib/integration/react';
import { Provider } from 'react-redux';
import ScrollToTop from 'components/ScrollToTop';
import { persistor, store } from './redux/store';

// theme
import ThemeConfig from './theme';
import GlobalStyles from './theme/globalStyles';
// components

// routes
import Router from './routes';

// font
import '@fontsource/plus-jakarta-sans';

import './index.css';
import './i18n';

// ----------------------------------------------------------------------

export default function App() {
  return (
    <ThemeConfig>
      <meta name="viewport" content="minimum-scale=1, initial-scale=1, width=device-width" />
      <ScrollToTop />
      <GlobalStyles />
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <Router />
        </PersistGate>
      </Provider>
    </ThemeConfig>
  );
}
