// material
import { Grid, Typography, Stack } from '@mui/material';
import useInnerWidth from 'hooks/useInnerWidth';
import GaugeChart from 'react-gauge-chart';
import { Pie, ResponsiveContainer, PieChart, Cell } from 'recharts';
import breakpoints from 'theme/breakpoints';
import styled from 'styled-components';
import { t } from 'i18next';
import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';
// ----------------------------------------------------------------------

const CustomGauageChart = styled(GaugeChart)`
  svg > .doughnut {
    background-color: red !important;
  }
`;
const TextPercent = styled.span`
  /* color: #ffffff; */
  position: absolute;
  bottom: -16px;
  right: ${(props) => (props.gauageSize === 120 ? '6px' : '10px')};
  z-index: 1;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 48px;
`;

export default function AppConfidenceChart({ avgConfidence }) {
  const width = useInnerWidth();
  const { t } = useTranslation();

  const isXs = width < breakpoints.values.sm;
  const ismd = width < breakpoints.values.lg;

  const gauageSize = useMemo(() => {
    let size = 160;
    if (width > 899 && width < 1120) {
      size = 120;
    }
    return size;
  }, [width]);

  const totalStyle = {
    textAlign: 'center',
    fontSize: isXs ? '32px' : '64px'
  };

  const data = [
    { name: 'confidence', value: Number(avgConfidence) },
    { name: 'non-confidence', value: Number(1 - avgConfidence) }
  ];

  return (
    <div style={{ height: '100%', textAlign: 'center' }}>
      <Typography style={{ height: isXs ? '60px' : undefined, textAlign: 'center' }}>
        <div
          style={{
            fontWeight: 700,
            fontSize: 30,
            lineHeight: '24px'
          }}
        >
          {t('gauge_title')}
        </div>
      </Typography>
      <div
        style={{
          paddingTop: '24px',
          height: 'calc(100% - 48px)',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center'
          // justifyContent: 'space-between'
        }}
      >
        <Grid container>
          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item xs={7} sm={7} md={7}>
                <div style={{ position: 'relative', display: 'flex', justifyContent: 'end' }}>
                  <CustomGauageChart
                    id="gauage-chart"
                    nrOfLevels={3}
                    arcsLength={[0.33, 0.33, 0.33]}
                    colors={['#7FCCEE', '#2AAAE2', '#0E3688']}
                    arcPadding={0}
                    percent={data[0].value || 0}
                    needleColor="#16143A"
                    marginInPercent={0.02}
                    formatTextValue={(value) => value}
                    cornerRadius={0}
                    hideText
                    style={{
                      zIndex: 0,
                      width: gauageSize
                    }}
                  />
                  <TextPercent gauageSize={gauageSize}>100</TextPercent>
                </div>
              </Grid>
              <Grid item xs={5} sm={5} md={5}>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: gauageSize === 120 ? 'center' : 'flex-start',
                    height: '100%'
                  }}
                >
                  <span>
                    <Grid container direction="column" gap="8px" justifyContent="center">
                      <Typography
                        variant="h4"
                        style={{
                          fontSize: gauageSize === 120 ? '18px' : '24px',
                          lineHeight: gauageSize === 120 ? '24px' : '24px',
                          textAlign: 'center'
                        }}
                      >
                        {t('gauge_percent')}
                      </Typography>
                      <Typography className="box-value" variant="h4" style={totalStyle}>
                        {data[0].value * 100}
                      </Typography>
                    </Grid>
                  </span>
                </div>
              </Grid>
            </Grid>
          </Grid>
          {/* <Grid item xs={12}>
            <Typography
              variant="h4"
              style={{
                paddingTop: '16px',
                fontStyle: 'normal',
                fontWeight: '700',
                fontSize: '16px',
                lineHeight: '18px',
                color: '#637381',
                textAlign: 'center',
                whiteSpace: 'pre-wrap'
              }}
            >
              <text style={{ color: 'red' }}>*</text> {t('gauge_desc')}
            </Typography>
          </Grid> */}
        </Grid>
      </div>
    </div>
  );
}
