import { Typography } from '@mui/material';
import React, { useMemo } from 'react';
import BasicMenu from 'components/BasicMenu';
import { LocationIcon } from 'components/icon/DashboardIcon';
import PropTypes from 'prop-types';
import useInnerWidth from 'hooks/useInnerWidth';
import breakpoints from 'theme/breakpoints';
import { useTranslation } from 'react-i18next';

function BasicLocationPicker(props) {
  const { t } = useTranslation();
  const width = useInnerWidth();
  const { list, location, setLocation } = props;

  const range = useMemo(() => [{ id: '', name: t('locations') }, ...list], [t, list]);

  return (
    <div>
      <BasicMenu
        className="button-select-locations"
        topic={
          <Typography style={{ fontSize: width < breakpoints.values.sm ? '4.5vw' : '24px' }}>
            {range.find((t) => t.id === location).name}
          </Typography>
        }
        icon={<LocationIcon width={20} mar />}
        items={range}
        setItem={() => null}
        setItemId={(id) => setLocation(id)}
      />
    </div>
  );
}

BasicLocationPicker.propTypes = {
  list: PropTypes.arrayOf(PropTypes.any).isRequired,
  location: PropTypes.string.isRequired,
  setLocation: PropTypes.func.isRequired
};

export default BasicLocationPicker;
