import { Navigate, useRoutes } from 'react-router-dom';
// layouts
import DashboardLayout from 'layouts/dashboard';
import DashboardApp from 'pages/DashboardApp';
import NotFound from 'pages/Page404';
import Unauthorized from 'pages/Page401';

// ----------------------------------------------------------------------

const privateRoute = {
  path: '/',
  element: <DashboardLayout />,
  children: [
    { path: '/', element: <DashboardApp /> },
    { path: '/:language', element: <DashboardApp /> }
  ]
};

export default function Router() {
  return useRoutes([
    privateRoute,
    { path: '404', element: <NotFound /> },
    { path: '401', element: <Unauthorized /> },
    { path: '*', element: <Navigate to="/404" replace /> }
  ]);
}
